import analytics from "@goalteller/app-kit/tools/analytics";
import { useThemeState } from "@goalteller/app-kit/wo-library/contexts/ThemeContext";
import { useRouter } from "next/router";
import { type ReactNode, useEffect } from "react";

/**
 * A wrapper component for the entire application.
 * All global contexts can be accessed here.
 */
export default function AppWrapper({ children }: { children: ReactNode }) {
  const { pathname } = useRouter();
  const { activeTheme } = useThemeState();

  useEffect(() => {
    analytics.track("page_view", {
      path: pathname,
      theme: activeTheme,
    });
  }, [activeTheme, pathname]);

  useEffect(() => {
    analytics.trackUtm();
  }, []);

  return children;
}
