import PricingBanner from "@goalteller/app-kit/components/Pricing/PricingBanner";

import ThemeWrapper from "./ThemeWrapper";
import styles from "./pricing.module.css";

export default function PricingLayout({
  isTransparent,
}: {
  isTransparent?: boolean;
}) {
  return (
    <ThemeWrapper
      className={isTransparent ? styles.transparentContainer : undefined}
    >
      <PricingBanner publicUrl={process.env.NEXT_PUBLIC_WEBAPP_GOALTELLER} />
    </ThemeWrapper>
  );
}
