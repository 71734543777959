import InTouchModal from "@goalteller/app-kit/components/InTouchModal";
import MaxWidthContainer from "@goalteller/app-kit/components/MaxWidthContainer";
import SectionTitle from "@goalteller/app-kit/components/SectionTitle";
import analytics from "@goalteller/app-kit/tools/analytics";
import { Button } from "@goalteller/app-kit/ye-design/components/atoms";
import DotSeperator from "@goalteller/app-kit/ye-design/components/atoms/DotSeperator/DotSeperator.js";
import { BREAKPOINTS } from "@goalteller/app-kit/ye-design/styles/media";
import clsx from "clsx";
import Image from "next/image";
import { useToggle, useWindowSize } from "react-use";

import BookCallButton from "../BookCallButton";
import ThemeWrapper from "../ThemeWrapper";
import GradientCard from "./GradientCard";
import styles from "./landingIntroDark.module.css";

const CARD_DATA = [
  { data: "1000 +", name: "Total Users" },
  { data: "1 of < 500", name: "SEBI Corp RIAs" },
  { data: "3000 Cr +", name: "Assets under advise" },
  { data: "90%", name: "Successful Calls" },
] as const;

export default function LandingIntro({
  theme = "dark",
}: {
  theme?: "dark" | "light";
}) {
  const [open, toggleOpen] = useToggle(false);
  const { width } = useWindowSize();
  const isMobile = width < BREAKPOINTS.sm;
  const isTablet = width < BREAKPOINTS.md;

  return (
    <>
      <div className={styles.rootContainer}>
        <MaxWidthContainer className={styles.mainContainer} isSection>
          <div className={styles.wrapper}>
            <div className={clsx(styles.leftContainer)}>
              <SectionTitle as="h1" className={styles.title} size="xhuge">
                Manage,{" "}
                <SectionTitle
                  as="span"
                  className={styles.title}
                  size="xhuge"
                  variant="gradient"
                >
                  Grow and <br /> Visualise
                </SectionTitle>{" "}
                Your Wealth <br /> All In One - App
              </SectionTitle>
              <div className={styles.infoText}>
                <DotSeperator
                  categories={[" Data Driven", "Tech Enabled", "Human Led"]}
                  className={styles.fundTag}
                  innerClassName={{ dot: styles.dotColor }}
                />
              </div>
              <div className={styles.landingBtnContainer}>
                <BookCallButton
                  className={styles.bookCallBtn}
                  onClick={() => {
                    analytics.track("Free_Advisor_Call", {
                      position: "landing intro",
                      text: "Free Advisor Call",
                    });
                  }}
                  openSamePage={false}
                >
                  Free Advisor Call
                </BookCallButton>
                <Button
                  onClick={() => {
                    window.open(
                      `${process.env.NEXT_PUBLIC_WEBAPP_GOALTELLER}/login`,
                      "_blank",
                    );
                  }}
                  theme="dark"
                  variant="outlined"
                >
                  Free Login
                </Button>
              </div>
            </div>

            <div className={styles.rightContainer}>
              <div className={styles.bannerImageContainer}>
                <Image
                  alt="financial growth by investing and with proper financial planning"
                  // blur data for image black
                  blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII="
                  className={styles.bannerImage}
                  height={0}
                  loading="eager"
                  placeholder="blur"
                  priority
                  sizes="100vw, (max-width: 600px) 100vw, 600px"
                  src="/hero.webp"
                  width={0}
                />
              </div>
            </div>
          </div>
        </MaxWidthContainer>
        <InTouchModal open={open} toggleOpen={toggleOpen} />
      </div>
      <ThemeWrapper className={styles.dataSection} theme={theme}>
        <MaxWidthContainer>
          <div className={styles.staticsContainer}>
            <div className={styles.centerTitleContainer}>
              <h2 className={styles.centerTitle}>
                Backed by Data, Driven by Results
              </h2>
            </div>
            {!(isMobile || isTablet) && (
              <GradientCard className={styles.numberCardWrapper}>
                {CARD_DATA.map((item, index) => (
                  <div className={styles.cardCenter} key={item.name}>
                    <div className={styles.numberCard}>
                      <h3 className={styles.number}>{item.data}</h3>
                      <p className={styles.cardName}>{item.name}</p>
                    </div>

                    {index < CARD_DATA.length - 1 && (
                      <div className={styles.verticalDivider} />
                    )}
                  </div>
                ))}
              </GradientCard>
            )}
          </div>
        </MaxWidthContainer>
        {(isMobile || isTablet) && (
          <div className={styles.numberCardsGrid}>
            {CARD_DATA.map((item) => {
              return (
                <GradientCard
                  className={styles.numberCardWrapper}
                  key={item.name}
                >
                  <div className={styles.numberCard} key={item.name}>
                    <h3 className={styles.number}>{item.data}</h3>
                    <p className={styles.cardName}>{item.name}</p>
                  </div>
                </GradientCard>
              );
            })}
          </div>
        )}
      </ThemeWrapper>
    </>
  );
}
